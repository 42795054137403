@font-face {
    font-family: 'IberPangea';
    src: url('./fonts/IberPangea-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IberPangea Light';
    src: url('./fonts/IberPangea-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IberPangea Medium';
    src: url('./fonts/IberPangea-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IberPangea SmBold';
    src: url('./fonts/IberPangea-SmBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'IberPangeaText Bold';
    src: url('./fonts/IberPangeaText-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'IberPangeaText Light';
    src: url('./fonts/IberPangeaText-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IberPangeaText';
    src: url('./fonts/IberPangeaText-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

html {
    height: 100%;
    width: 100%;
    font-family: 'IberPangeaText', sans-serif;
    padding: 0;
    margin: 0;
    /*overflow: hidden*/
}

body {
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
    color: #000000;
    background-color: #fff;
    height: 100%;
    width: 100%;
}

#reactApp {
    padding: 0;
    margin: 0;
    width: inherit;
    height: inherit;
}

.chatbot-content {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
    width: 100%;
    /*height: 100vh;*/
}

.footer {
    color: #000000;
    padding: 10px;
    /*text-align: center;*/
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 0;
    font-size: 10pt;
    background-color: #FFFFFF !important;
    z-index: 1000;
}
.footer > img {
    width: 140px;
}
div[role=group], div[role=complementary] {
    background-color: transparent !important;
}

div[role=complementary] {
    /*height: calc(100vh - 120px) !important;*/
}

.header {
    text-align: center;
    padding: 10px;
}

.react-film__filmstrip__list {
    flex-flow: column;
}

.react-film__flipper  {
    display: none;
}

.react-film__filmstrip__item:last-child {
    padding-right: 0px !important;
}

.react-film__filmstrip__item:first-child {
    padding-left: 0px !important;
}

.webchat__basic-transcript {
    min-height: 380px;
}

.webchat__basic-transcript__scrollable {
    overflow: unset;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .webchat__basic-transcript__scrollable {
        overflow: hidden;
    }
}

@supports (-ms-accelerator:true) {
    .webchat__basic-transcript__scrollable {
        overflow: hidden;
    }
}

div[role=form] ul li button {
    border-radius: 15px !important;
    border: solid 1px #00A443 !important;
    color: #000000 !important;
    width: 100%;
}

div[role=form] ul li button > nobr, div[role=form] ul li button > span {
    overflow-wrap: break-word;
    white-space: normal !important;
    text-align: left;
}

    div[role=form] ul li button:focus {
        border-radius: 15px !important;
        border: solid 2px #00A443 !important;
        background-color: #00A443;
        color: #000000 !important;
        width: 100%
    }

    div[role=form] ul li button::selection {
        border-radius: 15px !important;
        border: solid 2px #00A443 !important;
        color: #000000 !important;
        width: 100%
    }

h1 {
    color: #00402A;
    font-size: 18pt
}

.slider {
    display: none !important;
}

.webchat__send-box__main {
    padding: 10px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: transparent !important;
    border-color: transparent !important;
    position: fixed;
    bottom: 0;
    margin-top: 30px;
    width: 99%;
}

.webchat__send-box-text-box__input {
    padding: 6px !important;
    padding-left: 20px !important;
    border-radius: 5px;
    border: solid 1px #D8CFC7 !important;
}

.webchat__send-box__main svg {
    fill: white !important;
    width: 50px;
    height: 50px;
}

.webchat__send-box__main svg:hover {
    fill: #f0f0f0 !important;
}

.webchat__upload-button {
    display: none;
}

.webchat__send-box-text-box {
}

.webchat__microphone-button {
    margin-top: 3px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #00A443 !important;
    width: 45px !important;
    height: 45px !important;
    border-radius: 50px;
}

.webchat__icon-button__shade {
    background-color: transparent !important;
}

.webchat__microphone-button__icon {
    width: 30px !important;
    height: 30px !important;
}

@media (min-width: 965px) {
    .webchat__basic-transcript {
        min-height: 300px !important
    }

    .webchat__send-box__main {
        margin-bottom: 70px;
        margin-left: 25px;
        background-color: #FFF !important;
    }

    .footer {
        padding-left: 120px;
        padding-right: 120px;
        line-height: 1.5em;
        height: 50px;
        margin-left: 0px;
    }

    .react-film__main {
        margin-left: 25px;
        margin-right: 25px;
    }

    .webchat__microphone-button {
        margin-right: 40px;
    }
}

@media (max-width: 965px) {
    /*.webchat__stacked-layout__main {
        flex-direction: column;
        align-items: center;
    }*/
    .webchat__basic-transcript {
        min-height: 100px !important
    }
    .footer {
        font-size: 7pt;
        height: 55px;
        margin-left: 10px;
    }

        .footer > img {
            width: 40px;
        }

    .webchat__send-box__main {
        margin-bottom: 75px;
        background-color: #FFF !important;
    }

    .react-film__main {
        margin-left: 10px;
        margin-right: 10px;
    }

    .webchat__microphone-button {
        margin-right: 20px;
    }
}

.webchat__send-box__main input {
    background-color: white !important;
}

.webchat__initialsAvatar__initials {
    color: #000000
}

.webchat__suggested-actions {
    margin-bottom: 150px
}

.webchat__defaultAvatar
{
    border-radius: unset !important
}